export enum PLATFORM {
    WIN = "windows",
    MAC = "mac",
    MACIntel = "mac_intel",
    AND = "android",
    INDUS = "indus",
    IOS = "iOS",
    LINUX = "linux",
    LINUX18 = "linux_18",
    LINUX22 = "linux_22",
    WIN_PORATBLE = "windows_portable",
  }
  
  export enum THEME {
    DARK = "dark",
    LIGHT = "light",
  }
  
  export enum DEVICE {
    MOBILE = "mobile",
    TABLET = "tablet",
    DESKTOP = "desktop",
  }

  export enum RESOURCE_TYPE {
    PDF = 'pdf',
    VIDEO = 'video',
    BLOG = 'blog',
    WHITEPAPER = 'whitepaper',
  }