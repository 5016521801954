import React from 'react'
import parse from "html-react-parser";
import styles from './modelBuildingApproaches.module.scss';
import { modelBuildingApproaches } from '../../../../_shared/types/modelBuildingApproachesType'
import { Link } from 'react-router-dom';
import {ReactComponent as Arrow} from '../../../../assets/svg/arrow.svg'

function ModelBuildingApproaches({ data }: { data: modelBuildingApproaches[] }) {
    return (
        <div className={styles.approachesContainer}>
            {data.map((approach, index) => {
                return (
                    <div key={index} className={styles.approachesWrapper}>
                        <p className={styles.title}>{parse(approach.title)}</p>

                        <div className={styles.itemsContainer}>
                            {approach.items.map(({ svg: SVG, text }, index) => {
                                return (
                                    <div className={styles.item} key={index}>
                                        <SVG />
                                        <p className={styles.itemText}>{text}</p>
                                    </div>
                                )
                            })}
                        </div>

                        <Link to={approach.learnMorePage} reloadDocument>
                            <button className={styles.cta}>Learn More <Arrow /></button>
                        </Link>
                    </div>
                )
            })}
        </div>
    )
}

export default ModelBuildingApproaches