import { useEffect, useState } from "react";
import useGlobalHook from '../../hooks/global.hook'
import { RESOURCE_TYPE } from "../../constants/constants";
import { Resource } from "../../types/resourcesTypes";

const useResourceCardHook = () => {

    const [selectedResourceType, setSelectedResourceType] = useState<RESOURCE_TYPE | null>(null);

    const { disableScroll, enablePageScroll } = useGlobalHook();

    useEffect(() => {
        
        if (
            selectedResourceType !== RESOURCE_TYPE.PDF &&
            selectedResourceType !== RESOURCE_TYPE.WHITEPAPER &&
            selectedResourceType !== RESOURCE_TYPE.VIDEO
        ) {
            enablePageScroll();
        }
    }, [selectedResourceType])

    function clickHandler(resourceType: RESOURCE_TYPE, resourceUrl: Resource["resourceUrl"]) {
        
        setSelectedResourceType(resourceType)

        if (resourceType === RESOURCE_TYPE.BLOG) {
            const a = document.createElement('a');
            const url = resourceUrl;
            a.target = "_blank";
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

        if (resourceType === RESOURCE_TYPE.PDF) {
            disableScroll()
        }

        if (resourceType === RESOURCE_TYPE.WHITEPAPER) {
            disableScroll()
        }

        if (resourceType === RESOURCE_TYPE.VIDEO) {
            disableScroll()
        }
    }

    function handlePdfOutSideClick(){
        setSelectedResourceType(null)
    }



    return {
        clickHandler, handlePdfOutSideClick,
        selectedResourceType, setSelectedResourceType
    }
}

export default useResourceCardHook;