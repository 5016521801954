import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./customModal.module.scss";
import { OutsideClickHandler } from "../OutsideClickHandler/OutsideClickHandler";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
function CustomModal({ isOpen, onClose, children } : ModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            key="backdrop"
            className={styles.backdrop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <OutsideClickHandler onOutsideClick={onClose}>
              <motion.div
                key="modal"
                className={styles.modal}
                initial={{ scale: 0.7, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.7, opacity: 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 25, duration: 0.3, delay: 0.2 }}
              >
                {children}
              </motion.div>
            </OutsideClickHandler>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default CustomModal;

