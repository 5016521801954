import React, { useEffect, useState } from "react";
import styles from './resources.module.scss';
import RESOURCE_DATA from "./data/resources_data";
import ResourceCard from "../../_shared/components/ResouceCard/resourceCard.component";
import useResourceHook from "./resources.hook";
import { DEVICE } from "../../_shared/constants/constants";
import { useDeviceStore } from "../../StateManager/DeviceStore";



function Resources() {

  const {
    selectedKey, setSelectedKey,
  } = useResourceHook();

  const{device} = useDeviceStore()

  return (
    <div className={styles.resourcesPage}>
      {/* Left Panel: Resource Keys */}
      <div className={styles.categoriesWrapper}>
        <p className={styles.title}>Resources</p>
        {device !== DEVICE.MOBILE &&
        <hr />
      }
        <div className={styles.categories}>
          {Object.keys(RESOURCE_DATA).map((key) => (
            <p
              key={key}
              onClick={() => setSelectedKey(key)}
              className={`${key === selectedKey ? `${styles.activeCategory}` : ``}`}
            >
              {key.replace(/_/g, " ")}
            </p>
          ))}
        </div>

      </div>

      {/* Right Panel: Selected Resource Details */}
      <div className={styles.categoryTopicsWrapper}>
        {selectedKey ? (
          <div className={styles.categoryItemsWrapper}>
            {RESOURCE_DATA[selectedKey].map((category, index) => (

              <div className={styles.categoryItem} key={index} >
                <p className={styles.title}>{category.title}</p>
                <div className={styles.itemCardsContainer}>
                  {category.RESOURCES.map((resource, resourceIndex) => (
                    <ResourceCard resource={resource} index={resourceIndex} />
                  ))}
                </div>
              </div>

            ))}
          </div>
        ) : (
          <p>Select a category to view its resources</p>
        )}
      </div>

    </div>
  );
};

export default Resources;
