import React, { useEffect, useState } from 'react'
import styles from '../../_shared/styles/learnMorePage.module.scss'
import modalStyles from '../../_shared/styles/imageModal.module.scss';
import parse from 'html-react-parser';
import inferenceBuildingApproachesData from '../Home/data/inferenceBuildingApproachesData';
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { DEVICE } from '../../_shared/constants/constants';
import { AnimatePresence, motion } from 'framer-motion';
import { OutsideClickHandler } from '../../_shared/components/OutsideClickHandler/OutsideClickHandler';
import useGlobalHook from '../../_shared/hooks/global.hook';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CustomModal from '../../_shared/components/CustomModal/customModal.component';

function InferenceModelBuilding() {

    const { device } = useDeviceStore();

    const { disableScroll, enablePageScroll } = useGlobalHook();

    const [expandedImage, setExpandedImage] = useState<string | null>(null);

    useEffect(() => {

        if (expandedImage) disableScroll();

        else enablePageScroll();

    }, [expandedImage])

    function handleCloseImageModal() {
        setExpandedImage(null);
    }


    return (
        <div className={styles.learnMorePage}>
            {inferenceBuildingApproachesData.map((data, index) => {
                return (
                    <div className={styles.pageWrapper} key={index}>
                        <div className={styles.title}>
                            {parse(data.pageTitle)}
                        </div>

                        <div className={styles.itemsContainer}>
                            {data.pageContents.map((item, index) => {
                                return (
                                    <div className={styles.item} key={index}>

                                        <div className={styles.itemWrapper}>

                                            <div className={styles.textContainer}>
                                                <p className={styles.title}>{item.title}</p>
                                                <p className={styles.subTitle}>{item.subTitle}</p>
                                                {device === DEVICE.MOBILE &&
                                                    <img src={item.image} alt="illustration" />
                                                }
                                                <div className={styles.subPointsContainer}>
                                                    <ul>
                                                        {item.subPoints.map((subPoint, index) => {
                                                            return (
                                                                <li key={index}>{subPoint.text}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <br />
                                                    <p className={styles.description}>{item.description}</p>
                                                </div>
                                            </div>

                                            {device !== DEVICE.MOBILE &&
                                                <motion.div
                                                    key={item.image}
                                                    initial={{ opacity: 1 }}
                                                    animate={{ opacity: expandedImage ? 0 : 1 }}
                                                    exit={{ opacity: 1 }}
                                                    className={styles.imgContainer}
                                                    onClick={() => { setExpandedImage(item.image) }}>
                                                    <img src={item.image} alt="illustration" />
                                                    <OpenInFullIcon className={styles.expandIcon} fontSize="small" titleAccess='expand' role="img" />
                                                </motion.div>
                                            }

                                        </div>

                                        <div className={styles.pointsContainer}>
                                            {item.points.map((point, index) => {
                                                return (
                                                    <div className={styles.point} key={index}>
                                                        <p className={styles.number}>{index + 1}</p>
                                                        <p className={styles.description}>{point.text}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })
            }

            <CustomModal isOpen={expandedImage !== null} onClose={handleCloseImageModal}>
                {expandedImage &&
                    <div className={styles.modalContentWrapper}>
                        <div className={styles.imgContainer}>
                            <img src={expandedImage} alt="illustration" />
                        </div>
                    </div>
                }
            </CustomModal>



        </div>
    )
}

export default InferenceModelBuilding