const useGlobalHook = () => {

    function disableScroll(){
        document.body.style.overflow = 'hidden';
        
    }

    function enablePageScroll(){
        document.body.style.overflow = 'unset';
    }

    return{
        disableScroll,
        enablePageScroll,
    }
}


export default useGlobalHook