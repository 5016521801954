import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import Layout from './Layout/layout';
import { Home } from './Pages/Home/home';
import InferenceModelBuilding from './Pages/InferenceModelBuilding/inferenceModelBuilding';
import NotFound from './Pages/NotFound/notfound';
import Resources from './Pages/Resources/resources';
import WhyBuildWithInfinity from './Pages/WhyBuildWithInfinity/whyBuildWithInfinity';
import { ROUTES } from './_shared/constants/routes';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	const routes = AppRouter([
		{
			path: ROUTES.HOME,
			element: <Layout> </Layout>,

			children: [
				{
					path: ROUTES.HOME,
					element: <Home />
				},
				{
					path: ROUTES.WHY_BUILD_WITH_INFINITY,
					element: <WhyBuildWithInfinity />
				},
				{
					path: ROUTES.INFERENCE_MODEL_BUILDING,
					element: <InferenceModelBuilding />
				},
				{
					path: ROUTES.RESOURCES,
					element: <Resources />
				},
				{
					path: `/*`,
					element: <NotFound />
				}

			]

		},

	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
