import React, { useEffect, useState } from 'react'
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler';
//@ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Document, Page, pdfjs } from "react-pdf";
import styles from './pdfViewer.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as IconClose } from '../../../assets/svg/plusIcon.svg'
import useResourceCardHook from '../ResouceCard/resourceCard.hook';
import { RESOURCE_TYPE } from '../../constants/constants';
import { ENVIRONMENT } from '../../../environment';
import { whitepaperService } from '../../../App.registration';

function PdfViewer({ onOutsideClick, resourceUrl, resourceType }: { onOutsideClick: () => void, resourceUrl: string, resourceType: RESOURCE_TYPE }) {

    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [pdfName, setPdfName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


    // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    //     'pdfjs-dist/build/pdf.worker.min.mjs',
    //     import.meta.url,
    // ).toString();


    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    function clearForm() {
        setName('')
        setEmail('')
        setPdfName('')
        setTitle('')
        setIsLoading(false)
    }

    useEffect(() => {
        if (!resourceType) {
            clearForm()
        }
    }, [resourceType])

    useEffect(() => {
        //   setPdfName(`${pdfUrl?.split("/")[3].split(".")[0]}.pdf`)
        const pdf_url = resourceUrl?.split("/").slice(-1)[0]
        setPdfName(pdf_url?.replace("_preview", ""))
        setTitle(pdf_url?.replace("_preview", "").replace(".pdf" , ""))
    }, [resourceUrl])

    async function getTemporaryDomains(): Promise<string[]> {
        try {
            const response = await axios.get(ENVIRONMENT.disposableDomainsUrl);
            return response.data.split('\n');
        } catch (error) {
            console.error('Error fetching temporary domains:', error);
            return [];
        }
    }

    async function sendWhitepaper() {
        const response = await whitepaperService.WhitepaperSender(name, pdfName, email, title)

        if (response?.status === 200) {
            toast.success("Your requested Whitepaper will be sent to email. Thank You!");
            clearForm();
            setIsLoading(false)
        } else {
            toast.error("Failed to send, please try again later");
            setIsLoading(false)
        }
    }


    async function validateEmail(email: string) {
        var tempDomains = await getTemporaryDomains();
        var personalDomains = ['gmail.com', 'yahoo.com', 'outlook.com'];
        var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;


        if (emailPattern.test(email)) {
            var domain = email.split('@')[1];

            if (tempDomains.includes(domain)) {
                return 'Temporary email detected. Please provide a valid email.';
            } else if (personalDomains.includes(domain)) {
                return 'Personal email detected. Please use your organization email';
            } else {
                return 'valid';
            }
        } else {
            return 'Invalid email format.';
        }
    }

    async function handleFormSubmit(e: any) {
        e.preventDefault()
        setIsLoading(true)
        const response = await validateEmail(email);
        if (response === 'valid') {
            sendWhitepaper()
        }
        else {
            toast.error(response)
            setIsLoading(false)
        }
    }


    return (
        <div className={styles.pdfPreviewContainer} >
            <OutsideClickHandler onOutsideClick={() => { onOutsideClick(); }} >
                <div className={styles.pdfWrapper} autoFocus tabIndex={1} >
                    {/* {numPages > 0 && <IconClose onClick={()=> {onOutsideClick()}} className={styles.closeIcon} />} */}
                    <Document file={resourceUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(
                            { length: resourceType === RESOURCE_TYPE.WHITEPAPER ? 2 : numPages },
                            (_, i) => i + 1
                        ).map((page) => (
                            <Page
                                key={page}
                                scale={
                                    window.innerWidth < 900
                                        ? window.innerWidth < 500
                                            ? 0.5
                                            : 1.1
                                        : 1.5
                                }
                                pageNumber={page}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        ))}
                    </Document>

                    {numPages > 0 && resourceType === RESOURCE_TYPE.WHITEPAPER &&
                        <div className={styles.downloadFormWrapper}>
                            <form onSubmit={(e) => handleFormSubmit(e)} className={styles.formContainer}>
                                <div className={styles.formHeader}>
                                    <p className={styles.formTitle}>Unlock Full Access to the White Paper</p>
                                </div>
                                <div className={styles.formBody}>

                                    <div className={styles.inputContainer}>
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" value={name} required onChange={(e) => { setName(e.target.value) }} />
                                    </div>
                                    <div className={styles.inputContainer}>
                                        <label htmlFor="email">Your Email</label>
                                        <input type="email" value={email} required onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>

                                </div>
                                <div className={styles.formFooter}>
                                    <button type='submit' disabled={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default PdfViewer


