import {ReactComponent as Icon1} from '../../../assets/svg/howInfinityWorksIcon1.svg'
import {ReactComponent as Icon2} from '../../../assets/svg/howInfinityWorksIcon2.svg'
import {ReactComponent as Icon3} from '../../../assets/svg/howInfinityWorksIcon3.svg'
import {ReactComponent as Icon4} from '../../../assets/svg/howInfinityWorksIcon4.svg'
import {ReactComponent as Icon5} from '../../../assets/svg/howInfinityWorksIcon5.svg'
import { HowInfinityWorksCardProps } from '../../../_shared/types/howInfinityWorksType'

const howInfinityWorksData: HowInfinityWorksCardProps[] = [
    {
        icon: Icon1,
        title: 'Harnessing Idle Power for a New Generative AI Era',
        description: 'Zunu Infinity PaaS harnesses a vast network of computational resources—from public clouds, bitcoin miners, and server farms to enterprise servers, cloud services, and even regular desktops and mobile devices- turning idle processing power into a robust, distributed force that drives AI development.',
        cta: 'Dive into performance benchmarks',
        ctaUrl: '',
    },
    {
        icon: Icon2,
        title: 'Simplifying Complexity with Zunu Graph Partitioning',
        description: 'Zunu Infinity divides large AI models into smaller, manageable subgraphs, making complex AI networks easier to handle. These subgraphs are then distributed across a network of CPUs for efficient execution, enhancing scalability and performance.',
        cta: 'See graph partitioning in action',
        ctaUrl: '',
    },
    {
        icon: Icon3,
        title: 'Zunu Infinity Orchestration Server - For Smart Workload Management',
        description: 'When a task is submitted in Zunu Infinity, the Infinity Orchestration Server divides, assigns, and distributes tasks across available computational resources. It ensures low latency, fault tolerance, and region-specific distribution to comply with data residency laws, optimising performance and reliability.',
        cta: 'See our coverage across the globe',
        ctaUrl: '',
    },
    {
        icon: Icon4,
        title: 'Secure Processing: Private Input, Private Output',
        description: 'When an AI task reaches a CPU instance, each Zunu Infinity instance, called a Zunu Compute Node, executes AI model building, inference, and other distributed applications in a secure, isolated environment. No data is exchanged between the device and the Zunu Compute Node, ensuring complete privacy. Each compute node is Turing-complete and utilises FHE-based encryption to process encrypted data without ever decrypting it.',
        cta: 'Discover our flagship privacy libraries and SDKs',
        ctaUrl: '',
    },
    {
        icon: Icon5,
        title: 'Secure Model Deployment on EdgeDef',
        description: `AI model deployment at the edge is secure and private. With Zunu Infinity, the AI model's intellectual property remains fully safeguarded throughout its lifecycle. Easily view both final and intermediate results through a user-friendly desktop app. Once the task is complete, all data is securely erased from the system memory, ensuring privacy and security.`,
        cta: 'Dive into performance benchmarks',
        ctaUrl: '',
    },
]

export default howInfinityWorksData