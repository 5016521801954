import { infinityUsage } from "../../../_shared/types/infinityUsageType";
import Illustration1 from '../../../assets/webp/infinityUsageIllustration1.webp';
import Illustration2 from '../../../assets/webp/infinityUsageIllustration2.webp';
import Illustration3 from '../../../assets/webp/infinityUsageIllustration3.webp';

const infinityUsageData: infinityUsage[] = [
  {
    title: `Build LLMs with Just One Line of Code`,
    description: `Create foundational models with a single line of code and deploy them rapidly. Simplify AI development.`,
    image: Illustration1,
    code: `
//Create an encoder-decoder transformer with default configurations.       
EncoderDecoderTransformer transformer = new EncoderDecoderBuilder().build();
    
//Create an encoder transformer with default configurations.       
EncoderOnlyTransformer transformer = new EncoderBuilder().build();
    
//Create a decoder transformer with default configurations.
DecoderOnlyTransformer transformer = new DecoderBuilder().build();
         
`,
    language: 'Java',
  },

  {
    title: `Innovate with our Flexible SDKs`,
    description: `Build, integrate, and customise AI functionalities with our modular, extendible SDKs, tailored to meet specific needs and backed by comprehensive support.`,
    image: Illustration2,
    code: `
//Encoder-Decoder Transformer with custom context length.
EncoderDecoderTransformer transformer = new EncoderDecoderBuilder().contextLength(1000).build();
    
//Encoder Transformer with custom vector dimension
EncoderOnlyTransformer transformer = new EncoderBuilder().vectorDimension(1024).build();
    
//Decoder Transformer with custom block count.
DecoderOnlyTransformer transformer = new DecoderBuilder().numberOfBlocks(12).build();
         
`,
    language: 'Java',
  },

  {
    title: `Seamless Integration with Java, Python, and More`,
    description: `Enhance LLMs using any preferred programming language. We support Java, Python, and all other leading languages. Development is flexible and adaptable to specific environments.`,
    image: Illustration3,
    code: `
@Test
public void customComponent() {
  EncoderDecoderTransformer transformer = new EncoderDecoderBuilder().build();
  CNN cnn = new CNN();
  //Output of transformer fed into cnn.
  cnn.input(transformer);
  assertNotNull(transformer);
}

//Your custom CNN implementation.	
private static class CNN extends Component {

  public CNN() {
    super("CNN");
  }
}  
`,
    language: 'Java',
  },
];

export default infinityUsageData;
