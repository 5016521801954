import { useEffect, useState } from "react";
import { RESOURCE_TYPE } from "../../_shared/constants/constants";
import RESOURCE_DATA from "./data/resources_data";

const useResourceHook = () => {

    const [selectedKey, setSelectedKey] = useState<string | null>(Object.keys(RESOURCE_DATA)[0]);
    useEffect(() => {
        setSelectedKey(Object.keys(RESOURCE_DATA)[0])
      }, [])


    return {
        selectedKey, setSelectedKey,
    }
}

export default useResourceHook;