import React, { useEffect, useRef, useState } from 'react'
import styles from './navbar.module.scss'
import { ReactComponent as InfinityLogo } from '../../../assets/svg/infinity_logo.svg'
import { ReactComponent as ZirohLogo } from '../../../assets/svg/zirohLabs_logo_light.svg'
import { Link } from 'react-router-dom'
import { DEVICE } from '../../constants/constants'
import { useDeviceStore } from '../../../StateManager/DeviceStore'
import { AnimatePresence, motion, MotionConfig } from 'framer-motion'
import useGlobalHook from '../../hooks/global.hook'
import { ROUTES } from '../../constants/routes'



function Navbar() {

    const { device } = useDeviceStore();
    const {disableScroll, enablePageScroll} = useGlobalHook();

    const [active, setActive] = useState(false)


    useEffect(() => {
        setActive(false)
        document.body.style.overflow = 'unset';
    }, [device])


    useEffect(() => {
        if (active) {
            disableScroll()
        }
        else {
            enablePageScroll()
        }
    }, [active])

    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const element = navRef.current;

        // Function to handle ResizeObserver
        const handleResize = (entries: ResizeObserverEntry[]) => {
            for (let entry of entries) {
                const height = entry.contentRect.height;

                // Dynamically update the global CSS variable
                document.documentElement.style.setProperty("--nav_height", `${height}px`);
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (element) {
            resizeObserver.observe(element);
        }

        // Cleanup on component unmount
        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        };
    }, []);




    return (
        <nav ref={navRef} className={`${styles.navbar} ${active && `${styles.activeNavbar}`}`}>
            <div className={styles.navContents}>
                <Link to={ROUTES.HOME} reloadDocument className={styles.logoContainer}>
                    <InfinityLogo />
                </Link>

                <div className={styles.navOptionsContainer}>
                    {/* {device !== DEVICE.MOBILE &&
                        <Link to={""} className={styles.navOption} reloadDocument>About us</Link>
                    } */}
                    {device !== DEVICE.MOBILE &&
                        <Link to={`${ROUTES.RESOURCES}`} className={styles.navOption} reloadDocument>Resources</Link>
                    }

                    {/* <Link to={""} reloadDocument className={styles.cta}>Get Started</Link> */}

                    {device === DEVICE.MOBILE &&
                        <div className={styles.hamburger} onClick={() => { setActive(!active) }}>
                            <span className={`${styles.burger} ${active && `${styles.activeBurger1}`}`}></span>
                            <span className={`${styles.burger} ${active && `${styles.activeBurger2}`}`}></span>
                        </div>
                    }
                </div>
            </div>

            <AnimatePresence mode="wait">
                {active &&
                    <motion.div
                        key="navOptions"
                        initial={{ height: 0 }}
                        animate={{ height: "78vh" }}
                        exit={{ height: 0 }}
                        transition={{
                            duration: .4,
                            ease: "linear",
                        }}
                        className={styles.navOptions}
                    >
                        {/* <Link to={""} reloadDocument className={styles.navOption}>About Us</Link> */}
                        <Link to={`${ROUTES.RESOURCES}`} reloadDocument className={styles.navOption}>Resources</Link>

                        <div className={styles.poweredBy}>
                            <p>Powred by</p>
                            <ZirohLogo />
                        </div>
                    </motion.div>

                }
            </AnimatePresence>
        </nav>
    )
}

export { Navbar }