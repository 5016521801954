import { modelBuildingApproaches } from "../../../_shared/types/modelBuildingApproachesType";
import {ReactComponent as Icon1} from '../../../assets/svg/m1Icon.svg'
import {ReactComponent as Icon2} from '../../../assets/svg/m2Icon.svg'
import {ReactComponent as Icon3} from '../../../assets/svg/m3Icon.svg'
import {ReactComponent as Icon4} from '../../../assets/svg/m4Icon.svg'
import M1 from '../../../assets/webp/m1_illustration.webp'
import M2 from '../../../assets/webp/m2_illustration.webp'
import M3 from '../../../assets/webp/m3_illustration.webp'
import M4 from '../../../assets/webp/m4_illustration.webp'
import { ROUTES } from "../../../_shared/constants/routes";

const aiBuildingApproachesData: modelBuildingApproaches[] =[
    {
        title: `<p>AI Model Building Approaches in <span>Zunu Infinity</span></p>`,
        learnMorePage: `${ROUTES.WHY_BUILD_WITH_INFINITY}`,
        items: [
            {
                svg: Icon1,
                text: 'Small model with large dataset owned by a single party',
            },
            {
                svg: Icon2,
                text: 'Small Model, Non-Shareable Large Dataset Owned by Multiple Parties',
            },
            {
                svg: Icon3,
                text: 'Large model with large dataset owned by a single party',
            },
            {
                svg: Icon4,
                text: 'Large Model, Non-Shareable Dataset Owned by Multiple Parties',
            },
        ],

        pageTitle: `<p>Why Build with Zunu Infinity?</p>`,
        
        pageContents: [
            {
                title: 'Build Fast, Build Accurate',
                subTitle: 'Small model with large dataset owned by a single party',
                subPoints: [
                    {
                        text: 'Model and DataSet is owned by an organisation or individual.'
                    },
                    {
                        text: 'Training Data is large.'
                    },
                    {
                        text: 'Training occurs across multiple CPUs.'
                    },
                    {
                        text: 'Model owner determines the number and region of CPUs.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: M1,

                points: [
                    {
                        text: 'Define the model architecture and initial parameters, then submit them to the Zunu Infinity server.'
                    },
                    {
                        text: 'Select the desired datasets and specify the number of CPU instances and their locations in Zunu Infinity.'
                    },
                    {
                        text: 'Zunu Infinity divides the dataset into smaller pieces and distributes them across multiple CPU instances worldwide.'
                    },
                    {
                        text: 'Monitor real-time performance metrics, including which CPUs are executing tasks and the number of CPUs used.'
                    },
                ],
            },

            {
                title: 'Build Together, Build Better',
                subTitle: 'Small Model, Non-Shareable Large Dataset Owned by Multiple Parties',
                subPoints: [
                    {
                        text: 'A group is created for organizations or individuals who wish to train the model together without sharing their individual datasets with each other.'
                    },
                    {
                        text: 'Each groups on a single shared model with their datasets.'
                    },
                    {
                        text: 'Partial Results are accumulated and shared for mutual updates.'
                    },
                    {
                        text: 'Each member in the group determines the number and location of the CPUs.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: M2,

                points: [
                    {
                        text: 'Select model-building partners, which can include organizations or individuals worldwide.'
                    },
                    {
                        text: 'Each partner uploads their input datasets and initial parameters.'
                    },
                    {
                        text: 'Partners are notified about model execution and build their AI model using their own datasets without sharing data.'
                    },
                    {
                        text: 'Intermediate results are shared and integrated during the model-building process, resulting in a final AI model that is a collective effort of all partners.'
                    },
                ],
            },

            {
                title: 'Build Big, Build Easy',
                subTitle: 'Large model with large dataset owned by a single party',
                subPoints: [
                    {
                        text: `Model and Dataset is owned by a single party.`
                    },
                    {
                        text: 'Model is large and requires partition.'
                    },
                    {
                        text: 'Dataset is large and requires partition.'
                    },
                    {
                        text: 'Training occurs across multiple CPU instances.'
                    },
                    {
                        text: 'The model or dataset owner determines the number of CPU instances and their locations.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: M3,

                points: [
                    {
                        text: 'Define the model architecture and initial parameters, then submit them to the Zunu Infinity server.'
                    },
                    {
                        text: 'Zunu Infinity distributes both the model and dataset across multiple CPUs.'
                    },
                    {
                        text: 'Specify the number of CPUs and their locations in Zunu Infinity; the platform then assigns tasks accordingly.'
                    },
                    {
                        text: 'Monitor real-time performance, including which CPUs are executing tasks, the number of CPUs used, and key metrics.'
                    },
                ],
            },

            {
                title: 'Build Endless, Build United',
                subTitle: 'Large Model, Non-Shareable Dataset Owned by Multiple Parties',
                subPoints: [
                    {
                        text: 'A group is formed with members (organizations or individuals) who want to train their datasets without sharing them, using the same model.'
                    },
                    {
                        text: 'Model is large.'
                    },
                    {
                        text: 'Dataset is large.'
                    },
                    {
                        text: 'Each member runs its own training.'
                    },
                    {
                        text: 'Partial Results are accumulated and shared for mutual updates.'
                    },
                    {
                        text: 'Each member in the group determines the number and location of the CPUs'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: M4,

                points: [
                    {
                        text: 'Select model-building partners, which can include organizations or individuals worldwide.'
                    },
                    {
                        text: 'Specify the number and location of required CPU instances and upload this information to Zunu Infinity.'
                    },
                    {
                        text: 'Partition and distribute the model and datasets among partners; each partner builds their part of the model independently, with real-time access to intermediate or final results and performance metrics.'
                    },
                    {
                        text: 'Zunu Infinity integrates these parts into a final AI model.'
                    },
                ],
            },

        ]

    },
];

export default aiBuildingApproachesData;