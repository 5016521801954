import React, { useState } from 'react';
import styles from './howInfinityWorksCard.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../../assets/svg/arrow.svg';
import { HowInfinityWorksCardProps } from '../../../../_shared/types/howInfinityWorksType';


function HowInfinityWorksCard({
  icon: Icon,
  title,
  description,
  cta,
  ctaUrl
}: HowInfinityWorksCardProps) {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className={styles.card}>
      <Icon />
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>
        {readMore ?
          description
          : description.slice(0, 150)}
        <span
          className={styles.readMore}
          onClick={() => setReadMore(!readMore)}
        >
          {readMore ? ' read less' : '...read more'}
        </span>
      </p>
      {/* {cta && (
        <Link to={ctaUrl || '#'} className={styles.cta}>
          <p>{cta}</p>
          <Arrow />
        </Link>
      )} */}
    </div>
  );
}

export default HowInfinityWorksCard;
