import { CustomiseModelsCard } from "../../../_shared/types/customiseModelsCardType";
import {ReactComponent as Icon1} from '../../../assets/svg/healthIcon.svg';
import {ReactComponent as Icon2} from '../../../assets/svg/marketingIcon.svg';
import {ReactComponent as Icon3} from '../../../assets/svg/securityIcon.svg';
import {ReactComponent as Icon4} from '../../../assets/svg/mediaIcon.svg';
import {ReactComponent as Icon5} from '../../../assets/svg/educationIcon.svg';
import {ReactComponent as Icon6} from '../../../assets/svg/retailIcon.svg';

export const CustomiseModelsCardData: CustomiseModelsCard[] = [
    {
        icon: Icon1,
        title: 'Healthcare',
        description: 'Enhance diagnostics with object detection and image segmentation. Improve medical record management with image classification and text conversion.',
    },
    {
        icon: Icon2,
        title: 'Marketing',
        description: 'Optimise campaigns with text-video integration and keyword extraction. Create dynamic content with text-to-image and text generation.',
    },
    {
        icon: Icon3,
        title: 'Security',
        description: 'Boost safety with object detection and voice activity detection. Enhance monitoring with video classification and image analysis.',
    },
    {
        icon: Icon4,
        title: 'Media & Entertainment',
        description: 'Innovate storytelling with text-to-image and text generation. Increase accessibility with video-to-text transcription.',
    },
    {
        icon: Icon5,
        title: 'Education',
        description: 'Improve learning with text classification and summary writing. Support engagement with video-to-text and text-video integration.',
    },
    {
        icon: Icon6,
        title: 'Retail',
        description: 'Improve inventory management with image classification and object detection. Enhance customer experience with video classification and keyword extraction.',
    },
]