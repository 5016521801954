import React, { useEffect } from 'react';
import { Category, Resource } from '../../types/resourcesTypes';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow.svg';
import styles from './resourceCard.module.scss';
import useResourceCardHook from './resourceCard.hook';
import { DEVICE, RESOURCE_TYPE } from '../../constants/constants';
import PdfViewer from '../PdfViewer/pdfViewer.component';
import useResourceHook from '../../../Pages/Resources/resources.hook';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import CustomModal from '../CustomModal/customModal.component';
import ReactPlayer from 'react-player';

interface ResourceCardProps {
  resource: Resource;
  index: number;
}


function ResourceCard({ resource, index }: ResourceCardProps) {

  const playerStyle = {
    borderRadius: '1rem',
    overflow: 'hidden',
    aspectRation: "16 / 9",
  };

  const {
    clickHandler, handlePdfOutSideClick,
    selectedResourceType,
  } = useResourceCardHook();

  const { device } = useDeviceStore();


  return (
    <li className={styles.itemCard} key={index} onClick={() => clickHandler(resource.resourceType, resource.resourceUrl)}>
      <figure>
        <img src={resource.thumbnail} alt="thumbnail" />
      </figure>
      <div className={styles.titleContainer}>
        <p className={styles.title}>{resource.title}</p>
        {device !== DEVICE.MOBILE &&
          <div className={styles.iconContainer}>
            <Arrow />
          </div>
        }
      </div>


      {(selectedResourceType === RESOURCE_TYPE.PDF || selectedResourceType === RESOURCE_TYPE.WHITEPAPER) && resource.resourceUrl !== '' &&
        <PdfViewer onOutsideClick={handlePdfOutSideClick} resourceUrl={resource.resourceUrl} resourceType={resource.resourceType} />
      }

      {selectedResourceType === RESOURCE_TYPE.VIDEO &&
        <CustomModal isOpen={(selectedResourceType === RESOURCE_TYPE.VIDEO)} onClose={handlePdfOutSideClick}>
          <div className={styles.playerContainer}>
            <ReactPlayer
              playing={true}
              controls
              url={resource.resourceUrl}
              // light={<img className={styles.thumbnail} src={videos[activeIndex].thumbnail} alt="thumbnail" />}
              muted
              playsinline
              width='100%'
              height='100%'
              style={playerStyle}
            // playIcon={<PlayIcon className={styles.playIcon} />}
            />
          </div>

        </CustomModal>
      }
    </li>
  );
}

export default ResourceCard;
