import { RESOURCE_TYPE } from "../../../_shared/constants/constants"
import { ResourceData } from "../../../_shared/types/resourcesTypes";
import TransformationsThumbnail from '../../../assets/webp/transformation_functions_thumbnail.webp';
import ApiThumbnail from '../../../assets/webp/api_thumbnail.webp';
import DataStructuresThumbnail from '../../../assets/webp/data_structures_thumbnail.webp';
import { ROUTES } from "../../../_shared/constants/routes";


const RESOURCE_DATA: ResourceData = {
    DEVELOPER_RESOURCES: [
        {
            title: 'Developers',
            RESOURCES: [
                {
                    title: 'API Documentation',
                    thumbnail: ApiThumbnail,
                    resourceType: RESOURCE_TYPE.BLOG,
                    resourceUrl : ROUTES.GITBOOK_URL,
                },
                {
                    title: 'Data Structures',
                    thumbnail: DataStructuresThumbnail,
                    resourceType: RESOURCE_TYPE.PDF,
                    resourceUrl : 'https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/whitepapers/Memory%20Units.pdf',
                },
                {
                    title: 'Transformation Functions',
                    thumbnail: TransformationsThumbnail,
                    resourceType: RESOURCE_TYPE.PDF,
                    resourceUrl : 'https://vault-svc1.dev.ziroh.com/websiteserver/api/v1/whitepapers/TransformerFucntions.pdf',
                },
            ]
        }
    ],
    // LEARNING_CENTER: [
    //     {
    //         title: 'How to Videos',
    //         RESOURCES: [
    //             {
    //                 title: 'How to Install Zunu?',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.VIDEO,
    //                 resourceUrl : 'https://vimeo.com/978786224?share=copy',
    //             },
    //             {
    //                 title: 'How to register your Device?',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.VIDEO,
    //                 resourceUrl : 'https://vimeo.com/978786224?share=copy',
    //             },
    //             {
    //                 title: 'How to deregister your device.',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.VIDEO,
    //                 resourceUrl : 'https://vimeo.com/978786224?share=copy',
    //             },
    //         ]
    //     },
    //     {
    //         title: 'User Guides',
    //         RESOURCES: [
    //             {
    //                 title: 'guide 1',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.PDF,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'guide 1',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.PDF,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'guide 1',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.PDF,
    //                 resourceUrl : '',
    //             },
    //         ]
    //     },
    // ],
    // INSIGHTS_UPDATES: [
    //     {
    //         title: 'Blogs',
    //         RESOURCES: [
    //             {
    //                 title: 'resource 1',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.BLOG,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'resource 2',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.BLOG,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'resource 3',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.BLOG,
    //                 resourceUrl : '',
    //             },
    //         ]
    //     },
    //     {
    //         title: 'White Papers',
    //         RESOURCES: [
    //             {
    //                 title: 'resource 1',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.WHITEPAPER,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'resource 2',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.WHITEPAPER,
    //                 resourceUrl : '',
    //             },
    //             {
    //                 title: 'resource 3',
    //                 thumbnail: TransformationsThumbnail,
    //                 resourceType: RESOURCE_TYPE.WHITEPAPER,
    //                 resourceUrl : '',
    //             },
    //         ]
    //     }
    // ],
}

export default RESOURCE_DATA