import CapabilitiesIllustration1 from '../../../assets/webp/capabilities_illustration1.webp';
import CapabilitiesIllustration2 from '../../../assets/webp/capabilities_illustration2.webp';
import CapabilitiesIllustration3 from '../../../assets/webp/capabilities_illustration3.webp';
import CapabilitiesIllustration1_expanded from '../../../assets/webp/capabilities_illustration1_expanded.webp';
import CapabilitiesIllustration2_expanded from '../../../assets/webp/capabilities_illustration2_expanded.webp';
import CapabilitiesIllustration3_expanded from '../../../assets/webp/capabilities_illustration3_expanded.webp';
import { CapabilitiesCardData } from '../../../_shared/types/capabilitiesCardType';

import styles from '../home.module.scss';



const CapabilitiesCardsData: CapabilitiesCardData[] = [
    {
        id: 1,
        image: CapabilitiesIllustration1,
        image_large: CapabilitiesIllustration1_expanded,
        title: 'Unlimited Potential',
        description: `
           <div className=${styles.textBox}>
            <p className=${styles.title}>No Limits on Input Token Size</p>
            <p className=${styles.description}>Traditional AI models encounter performance bottlenecks when processing inputs in a single instance. <span className=${styles.highlight}>Zunu Infinity removes these limitations</span>. Supports <span className=${styles.highlight}>infinite dimensionality of tokens</span>. Transitions AI from Generic Artificial Intelligence to <span className=${styles.textGradientPinkBlue}>Infinite Artificial Intelligence.</span></p>
           </div>

           <div className=${styles.textBox}>
            <p className=${styles.title}>No Limits on Model Parameters</p>
            <p className=${styles.description}>Create <span className=${styles.textGradientPinkBlue}>AI Models</span> with unlimited parameters. Multiple <span className=${styles.highlight}>encoding, decoding, encoder-decoder</span>, and <span className=${styles.highlight}>self-attention blocks</span> are supported. Facilitates fully connected networks with an infinite number of hidden layers and neurons. Infinite parametric models seamlessly adapt to any complexity or scale of AI tasks. </p>
           </div>

           <div className=${styles.textBox}>
            <p className=${styles.title}>No Limits on Iterations</p>
            <p className=${styles.description}>Built on a <span className=${styles.highlight}>CPU-centric architecture</span>, independent of <span className=${styles.highlight}>GPUs</span>. Eliminates budget constraints associated with <span className=${styles.textGradientPinkBlue}>AI development</span>. Enables developers to execute more tasks and build more efficient AI systems within the <span className=${styles.textGradientGreenCyan}>same budget</span>.</p>
           </div>
           
           <div className=${styles.textBox}>
           <p className=${styles.title}>AI Model Control at Your Fingertips</p>
           <p className=${styles.description}>Seamless control over <span className=${styles.highlight}>AI tasks</span>. Desktop app allows easy submission and management of AI jobs. Mobile app enables <span className=${styles.highlight}>real-time monitoring and control from a phone</span>. Functions include <span className=${styles.highlight}>starting, stopping, pausing jobs, and tracking performance metrics</span>. Keeps <span className=${styles.textGradientPinkBlue}>developers</span> connected and informed always.</p>
          </div>
        `
        ,
    },
    {
        id: 2,
        image: CapabilitiesIllustration2,
        image_large: CapabilitiesIllustration2_expanded,
        title: 'Unlimited DataSet size',
        description: `
        <div className=${styles.textBox}>
         <p className=${styles.title}>Breaking Down Large Datasets</p>
         <p className=${styles.description}>Divides <span className=${styles.highlight}>large datasets</span> into infinitely small segments. Enables execution of <span className=${styles.textGradientPinkBlue}>complex models</span> on mobile devices. Makes <span className=${styles.highlight}>advanced data processing</span> accessible anywhere.</p>
        </div>

        <div className=${styles.textBox}>
         <p className=${styles.title}>Flexible Partitioning</p>
         <p className=${styles.description}>Supports <span className=${styles.highlight}>horizontal, vertical,</span> or <span className=${styles.highlight}>combined partitioning</span> of datasets.Ensures efficient data management tailored to specific needs. Optimises performance and adapts to <span className=${styles.textGradientGreenCyan}>diverse data processing</span> requirements.</p>
        </div>

        <div className=${styles.textBox}>
         <p className=${styles.title}>Choose Your Dataset Location</p>
         <p className=${styles.description}>Allows developers to select the <span className=${styles.textGradientGreenCyan}>physical locations</span> of their datasets. Ensures compliance with <span className=${styles.highlight}>data privacy</span> and <span className=${styles.highlight}>residency regulations</span> across countries.</p>
        </div>
        
        <div className=${styles.textBox}>
        <p className=${styles.title}>Enhanced Privacy and Security</p>
        <p className=${styles.description}>Developers <span className=${styles.highlight}>can encrypt</span> all or parts of their <span className=${styles.highlight}>datasets</span>. Ensures sensitive information remains <span className=${styles.textGradientPinkBlue}>secure</span> during <span className=${styles.textGradientPinkBlue}>data processing</span> and <span className=${styles.textGradientPinkBlue}>model training</span>.</p>
       </div>
       `
    },
    {
        id: 3,
        image: CapabilitiesIllustration3,
        image_large: CapabilitiesIllustration2_expanded,
        title: 'Build Collaboratively',
        description: `
        <div className=${styles.textBox}>
         <p className=${styles.title}>Multi-Organizational Collaboration</p>
         <p className=${styles.description}>Enables <span className=${styles.highlight}>collaboration</span> among multiple organisations. Supports building a <span className=${styles.textGradientPinkBlue}>single model</span> or <span className=${styles.textGradientPinkBlue}>multiple models</span> collaboratively. Leverages combined strengths and data from different parties.</p>
        </div>

        <div className=${styles.textBox}>
         <p className=${styles.title}>Build over Non-Shareable Datasets</p>
         <p className=${styles.description}>Prioritises <span className=${styles.textGradientGreenCyan}>data confidentiality</span> in collaborative environments. Ensures datasets <span className=${styles.highlight}>remain isolated</span> and not shared among participants. Preserves the <span className=${styles.textGradientPurple}>privacy and security</span> of each party’s data.</p>
        </div>

        <div className=${styles.textBox}>
         <p className=${styles.title}>Improved Model Performance, Remove Bias and Accuracy</p>
         <p className=${styles.description}>Leverages diverse datasets to reduce biases. <span className=${styles.highlight}>Improves model generalisation</span> across various scenarios. Creates models that are more representative, fair, and capable of handling <span className=${styles.textGradientPinkBlue}>diverse data inputs.</span></p>
        </div>
       `
    },
];

export default CapabilitiesCardsData