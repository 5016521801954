import React, { useEffect, useRef, useState } from 'react'
import { useDeviceStore } from '../../StateManager/DeviceStore';
import { useOsStore } from '../../StateManager/OsStore';
import { DEVICE } from '../../_shared/constants/constants';
import styles from './home.module.scss';
import modalStyles from '../../_shared/components/CustomModal/customModal.module.scss';
import { ReactComponent as ScrollArrow } from '../../assets/svg/scroll_arrows.svg';
import { ReactComponent as WhyInfinityIcon1 } from '../../assets/svg/whyInfinity_icon1.svg';
import { ReactComponent as WhyInfinityIcon2 } from '../../assets/svg/whyInfinity_icon2.svg';
import { ReactComponent as WhyInfinityIcon3 } from '../../assets/svg/whyInfinity_icon3.svg';
import { ReactComponent as WhyInfinityIcon4 } from '../../assets/svg/whyInfinity_icon4.svg';
import { ReactComponent as ReadMoreArrow } from '../../assets/svg/readMore_arrow.svg';
import { ReactComponent as PlusIcon } from '../../assets/svg/plusIcon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import CapabilitiesIllustration1 from '../../assets/webp/capabilities_illustration1.webp';
import CapabilitiesIllustration2 from '../../assets/webp/capabilities_illustration2.webp';
import CapabilitiesIllustration3 from '../../assets/webp/capabilities_illustration3.webp';
import QR_mockup from '../../assets/webp/downloadQRMockup.webp';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import useGlobalHook from '../../_shared/hooks/global.hook';
import { OutsideClickHandler } from '../../_shared/components/OutsideClickHandler/OutsideClickHandler';
import { CapabilitiesCardData } from '../../_shared/types/capabilitiesCardType';
import CapabilitiesCardsData from './data/capabilitiesCardsData';
import parse from "html-react-parser";
import ModelBuildingApproaches from './components/modelBuildingApproaches/modelBuildingApproaches.component';
import aiBuildingApproachesData from './data/aiBuildingApproachesData';
import inferenceBuildingApproachesData from './data/inferenceBuildingApproachesData';
import howInfinityWorksData from './data/howInfinityWorksData';
import { Link } from 'react-router-dom';
import HowInfinityWorksCard from './components/howInfinityWorksCard/howInfinityWorksCard.component';
import infinityUsageData from './data/infinityUsageData';
import CodeBlock from './components/codeBlock/codeBlock.component';
import { CustomiseModelsCardData } from './data/customiseModelsCardsData';
import CustomModal from '../../_shared/components/CustomModal/customModal.component';


function Home() {
    const { device } = useDeviceStore()
    const { os } = useOsStore()
    const whyInfinityRef = useRef<HTMLDivElement>(null);
    const { disableScroll, enablePageScroll } = useGlobalHook();
    const [whyInfinityReadMore, setWhyInfinityReadMore] = useState(false);
    const [expandedCard, setExpandedCard] = useState<CapabilitiesCardData | null>(null);


    function scrollWindow() {
        whyInfinityRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 2000, min: 730 },
            items: 3,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 730, min: 0 },
            items: 1.2,
            slidesToSlide: .8
        }
    };

    function handleCardClick(card: CapabilitiesCardData) {
        setExpandedCard(card);
    };

    function handleCloseModal() {
        setExpandedCard(null)
    }

    useEffect(() => {
        if (expandedCard) {
            disableScroll()
        }
        else {
            enablePageScroll()
        }
    }, [expandedCard])


    return (
        <div className={styles.home}>

            <div className={styles.heroSection}>
                <div className={styles.videoWrapper}>
                    <video autoPlay muted loop playsInline controls={false} controlsList="nodownload" preload="auto" className={styles.bgVideo}>
                        <source src={'/webm/binary_bg_video.webm'} type="video/webm" />
                        <source src={'/mp4/binary_bg_video.mp4'} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className={styles.overlay}></div>

                </div>
                <p className={styles.title}>Imagining Infinite Intelligence</p>
                <p className={styles.subTitle}>Cost-conscious, Confidential and Carbon-neutral AI Platform.</p>
                {/* <button className={styles.cta}>Get Started with Zunu Infinity</button> */}

                {device !== DEVICE.MOBILE &&
                    <div className={styles.scrollBtn} onClick={scrollWindow}>
                        <ScrollArrow />
                    </div>
                }
            </div>

            <div className={styles.whyInfinity} ref={whyInfinityRef}>

                <div className={styles.textContent}>
                    <p className={styles.title}>Why Build with Zunu Infinity?</p>
                    {device !== DEVICE.MOBILE &&
                        <p className={styles.text}>
                            Zunu Infinity leverages CPUs instead of GPUs, making it a super cost-effective solution for building and hosting large-scale, highly performant AI models while significantly lowering energy consumption and reducing carbon footprint.
                            <br />
                            <br />
                            Zunu Infinity makes AI accessible to ALL. Zunu Infinity is Data Privacy and Data Residency Regulations compliant by design. Both model building and inference are distributed.
                        </p>
                    }
                </div>

                <div className={styles.cardsContainer}>
                    <div className={styles.card}>
                        <div className={styles.iconContainer}>
                            <WhyInfinityIcon1 />
                        </div>
                        <p className={styles.cardText}>Build AI Models at 50% lesser cost.</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.iconContainer}>
                            <WhyInfinityIcon2 />
                        </div>
                        <p className={styles.cardText}>1/3rd reduction in Carbon Emissions.</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.iconContainer}>
                            <WhyInfinityIcon3 />
                        </div>
                        <p className={styles.cardText}>Auto-compliance with Regional Data Privacy Residency Regulations.</p>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.iconContainer}>
                            <WhyInfinityIcon4 />
                        </div>
                        <p className={styles.cardText}>Run from Zunu Infinity PaaS globally.</p>
                    </div>
                </div>

                {device === DEVICE.MOBILE &&
                    <div className={styles.textContent}>
                        <div className={styles.readMore} onClick={() => setWhyInfinityReadMore(!whyInfinityReadMore)}>
                            <p>{whyInfinityReadMore ? 'Read Less' : 'Read More'}</p>
                            <ReadMoreArrow className={`${styles.readMoreArrow} ${whyInfinityReadMore && `${styles.readMoreArrowActive}`}`} />
                        </div>

                        <AnimatePresence mode="wait" initial={false} >
                            {whyInfinityReadMore && (
                                <motion.p
                                    key="whyInfinityText"
                                    initial={{ clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)' }}
                                    animate={{ clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }}
                                    exit={{ clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)' }}
                                    transition={{
                                        duration: 0.5,
                                        ease: 'easeInOut',
                                    }}
                                    className={`${styles.text}`}
                                >
                                    Zunu Infinity leverages CPUs instead of GPUs, making it a super cost-effective solution for building and hosting large-scale, highly performant AI models while significantly lowering energy consumption and reducing carbon footprint.
                                    <br />
                                    <br />
                                    Zunu Infinity makes AI accessible to ALL. Zunu Infinity is Data Privacy and Data Residency Regulations compliant by design. Both model building and inference are distributed.
                                </motion.p>
                            )}
                        </AnimatePresence>
                    </div>
                }

            </div>

            <div className={styles.capabilities}>
                <p className={styles.title}>Zunu Infinity's Capabilities:</p>

                <Carousel
                    swipeable={true}
                    draggable={device === DEVICE.MOBILE}
                    showDots={false}
                    responsive={responsive}
                    containerClass={styles.carousel}
                    customTransition={'transform 800ms ease-in-out'}
                    arrows={false}
                >
                    {CapabilitiesCardsData.map((card) => (
                        <motion.div
                            key={card.id}
                            className={styles.card}
                            initial={{ opacity: 1 }}
                            animate={{ opacity: expandedCard?.id === card.id ? 0 : 1 }}
                            exit={{ opacity: 1 }}
                            onClick={() => handleCardClick(card)}
                        >
                            <div className={styles.imgContainer}>
                                <img src={card.image} alt="" />
                            </div>

                            <div className={styles.textContainer}>
                                <p className={styles.title}>{card.title}</p>
                                <div className={styles.expandCta}><PlusIcon /></div>
                            </div>
                        </motion.div>
                    ))}
                </Carousel>

                <CustomModal isOpen={expandedCard !== null} onClose={handleCloseModal}>
                    {expandedCard &&
                        <div className={styles.modalContentWrapper}>
                            <div className={styles.clsButton} onClick={handleCloseModal}>
                                <PlusIcon />
                            </div>

                            <div className={styles.imgContainer}>
                                <img src={expandedCard.image_large} alt="illustration" />
                            </div>
                            <div className={styles.textContainer}>
                                {parse(expandedCard.description)}
                            </div>
                        </div>
                    }
                </CustomModal>
            </div>

            <ModelBuildingApproaches data={aiBuildingApproachesData} />

            <ModelBuildingApproaches data={inferenceBuildingApproachesData} />

            <div className={styles.howInfinityWorks}>
                <p className={styles.title}>How does <span>Zunu Infinity</span> work</p>

                <div className={styles.cardsContainer}>
                    {howInfinityWorksData.map((cardData, index) => (
                        <HowInfinityWorksCard {...cardData} key={index} />
                    ))}
                </div>

            </div>

            {/* {device !== DEVICE.MOBILE &&
                <div className={styles.downloadBanner}>
                    <div className={styles.bannerText}>
                        <p className={styles.title}>Zunu Infinity on your desktop and mobile</p>
                        <button className={styles.cta}>Install Zunu Infinity on desktop</button>
                    </div>
                    <div className={styles.bannerQR}>
                        <img src={QR_mockup} alt="qr code" />
                    </div>
                </div>
            } */}

            <div className={styles.usageContainer}>
                <p className={styles.title}>Ease of Use</p>

                <div className={styles.howToUseSteps}>
                    {infinityUsageData.map((data, index) => {
                        return (
                            <div className={styles.howToStep} key={index}>
                                <div className={styles.textContainer}>
                                    <p className={styles.title}>{data.title}</p>
                                    <p className={styles.description}>{data.description}</p>
                                    {/* <img src={data.image} alt="illustration" /> */}
                                </div>

                                <div className={styles.snippetContainer}>
                                    <CodeBlock code={data.code} language={data.language} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className={styles.customiseModels}>
                <p className={styles.title}>Customise Models for Numerous AI Applications</p>

                <div className={styles.cardsContainer}>
                    {CustomiseModelsCardData.map(({ icon: Icon, title, description }, index) => {
                        return (
                            <div className={styles.card}>
                                <Icon />
                                <p className={styles.title}>{title}</p>
                                <p className={styles.description}>{description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export { Home }