import { modelBuildingApproaches } from "../../../_shared/types/modelBuildingApproachesType";
import {ReactComponent as Icon1} from '../../../assets/svg/m1Icon.svg'
import {ReactComponent as Icon2} from '../../../assets/svg/m2Icon.svg'
import {ReactComponent as Icon3} from '../../../assets/svg/m3Icon.svg'
import {ReactComponent as Icon4} from '../../../assets/svg/m4Icon.svg'
import I1 from '../../../assets/webp/i1_illustration.webp'
import I2 from '../../../assets/webp/i2_illustration.webp'
import I3 from '../../../assets/webp/i3_illustration.webp'
import I4 from '../../../assets/webp/i4_illustration.webp'
import { ROUTES } from "../../../_shared/constants/routes";

const inferenceBuildingApproachesData: modelBuildingApproaches[] =[
    {
        title: `<p>Inference Model Building Approaches in <span>Zunu Infinity</span></p>`,
        learnMorePage: `${ROUTES.INFERENCE_MODEL_BUILDING}`,
        items: [
            {
                svg: Icon1,
                text: 'Small Inference Model',
            },
            {
                svg: Icon2,
                text: 'Large Inference Model',
            },
            {
                svg: Icon3,
                text: 'Composite Inference Model for Single Ownership',
            },
            {
                svg: Icon4,
                text: 'Composite Inference Model with Non-Sharable Multi-Party Ownership',
            },
        ],

        pageTitle: `<p>Inference Model Building Approaches in <span>Zunu Infinity</span></p>`,
        
        pageContents: [
            {
                title: 'Small Inference Model',
                subTitle: 'Quick Inference, Quality Results',
                subPoints: [
                    {
                        text: 'A fully trained model is uploaded to the Zunu Infinity server.'
                    },
                    {
                        text: 'Developers specify the number of active instances required for availability and fault tolerance.'
                    },
                    {
                        text: 'The model is replicated across multiple CPU instances based on these requirements.'
                    },
                    {
                        text: 'Load balancer is configured to manage inference requests.'
                    },
                    {
                        text: 'Users (applications, organisations, or individuals) request access to the model for inference via the Zunu Infinity server.'
                    },
                    {
                        text: 'The server locates the appropriate instances, sends input data for inference, and returns the results.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: I1,

                points: [
                    {
                        text: 'Submit the fully built AI model to the Zunu Infinity server and specify the number of model instances to replicate.'
                    },
                    {
                        text: 'Zunu Infinity provides URLs for input parameters and adheres to strict Service Level Agreements (SLAs) for reliable performance.'
                    },
                    {
                        text: 'The model is replicated as requested by the AI model developer and distributed across various CPUs.'
                    },
                    {
                        text: 'Inference occurs globally, with location choices ensuring privacy and residency regulations compliance.'
                    },
                ],
            },

            {
                title: 'Large Inference Model',
                subTitle: 'Large Model, Limitless Inference',
                subPoints: [
                    {
                        text: 'A fully trained model is uploaded to the Zunu Infinity server.'
                    },
                    {
                        text: 'Model is partitioned into smaller subgraphs.'
                    },
                    {
                        text: `Developer specifies the number of active instances required for availability and fault tolerance.`
                    },
                    {
                        text: 'The model is replicated across the Zunu Infinity server according to these requirements.'
                    },
                    {
                        text: 'The large model is distributed across multiple CPUs, with a load balancer managing inference requests.'
                    },
                    {
                        text: 'Users (applications, organizations, or individuals) request access for inference through the Zunu Infinity server, which locates the appropriate instances, processes the input data, and returns the results.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: I2,

                points: [
                    {
                        text: 'Submit the AI model to the Zunu Infinity server.'
                    },
                    {
                        text: 'The model is partitioned into smaller subgraphs and deployed across multiple CPUs based on the replication request from the model builder.'
                    },
                    {
                        text: 'Inference is conducted globally, with location flexibility to ensure privacy and residency laws compliance.'
                    },
                    {
                        text: 'Zunu Infinity provides URLs for accessing large models, similar to those for small models, enabling easy access for AI developers.'
                    },
                ],
            },

            {
                title: 'Composite Inference Model for Single Ownership',
                subTitle: 'Connected Models, Consistent Results',
                subPoints: [
                    {
                        text: `Combines multiple fully trained models to create a single inference engine.`
                    },
                    {
                        text: `Combined model is partitioned into smaller subgraphs.`
                    },
                    {
                        text: 'Model developer specifies the number of active instances needed for availability and fault tolerance.'
                    },
                    {
                        text: 'Composite model is replicated across the Zunu Infinity server as required.'
                    },
                    {
                        text: 'A load balancer manages inference requests.'
                    },
                    {
                        text: 'Users (applications, organizations, or individuals) request access for inference, and the Zunu Infinity server locates the appropriate instances, processes input data, and returns the results.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: I3,

                points: [
                    {
                        text: 'Define a connected graph where each subgraph represents an individual model.'
                    },
                    {
                        text: 'Submit requirements such as the number of machines, user support per second, and desired locations.'
                    },
                    {
                        text: 'Zunu Infinity manages deployment and execution, ensuring seamless integration and operation.'
                    },
                ],
            },

            {
                title: 'Composite Inference Model with Non-Sharable Multi-Party Ownership',
                subTitle: 'Multi-party Models, Maximum Inference',
                subPoints: [
                    {
                        text: 'Combines multiple fully trained models into a single inference engine.'
                    },
                    {
                        text: 'Allows each party to retain control over their model architecture and parameters.'
                    },
                    {
                        text: 'The model is partitioned into smaller subgraphs and distributed across multiple CPUs.'
                    },
                    {
                        text: 'Model developer specifies the number of active instances needed for availability and fault tolerance.'
                    },
                    {
                        text: 'Composite model is replicated across the Zunu Infinity server accordingly.'
                    },
                    {
                        text: 'A load balancer manages inference requests.'
                    },
                    {
                        text: 'When users (applications, organisations, or individuals) request access for inference, the Zunu Infinity server locates the appropriate instances, processes the input data, and returns the results.'
                    },
                ],

                description: 'Developers can execute this Model type with the following steps:',
                image: I4,

                points: [
                    {
                        text: 'Define the individual models and establish connections between them.'
                    },
                    {
                        text: 'Create a connected graph with each subgraph representing an individual model.'
                    },
                    {
                        text: 'Submit requirements, including machine count, user capacity per second, and locations, to Zunu Infinity.'
                    },
                    {
                        text: 'Zunu Infinity handles deployment and execution, and the inference steps proceed as usual.'
                    },
                ],
            },

        ]

    },
];

export default inferenceBuildingApproachesData;