import React, { useState } from 'react'
import styles from './codeBlock.module.scss';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNight, sunburst } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { codeBlockProps } from '../../../../_shared/types/codeBlockType';
import {ReactComponent as CopyIcon} from '../../../../assets/svg/copyIcon.svg';

function CodeBlock({ code, language }: codeBlockProps) {
  const [copied, setIsCopied] = useState(false);

  function handleCopy() {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
}

  return (
    <div className={styles.codeBlockContainer}>
      <SyntaxHighlighter className={styles.syntaxHighLighter} language={language} style={sunburst} >
        {code}
      </SyntaxHighlighter>

      <CopyToClipboard text={code} onCopy={handleCopy}>
      <button className={styles.copyButton}>
        {!copied && <CopyIcon />}
        {copied ? 'Copied!' : 'Copy'}
        </button>
        </CopyToClipboard>

    </div>
  )
}

export default CodeBlock
